<script setup lang="ts">
import { VideoProps } from "./Video.props"
const props = withDefaults(defineProps<VideoProps>(), {
  hasAutoplay: true,
  isPortraitInMobile: false,
  onPlayerReady: () => null,
  pauseChanged: () => null
})

const isPortraitOnMobile = () => {
  if (props.isPortraitInMobile && sm.value) {
    return "auto"
  }
  return "16:9"
}
</script>

<template>
  <ClientOnly>
    <AppVideo
      :class="appVideoClasses"
      :videoSrc="sm && mobileVideoSrc ? mobileVideoSrc : videoSrc"
      :youtubeId="youtubeId ?? undefined"
      :vimeoId="vimeoId ?? undefined"
      :clickToPlay="sm ? true : false"
      :clickToPlaySettings="{ useOnMobile: true }"
      :playerSettings="{
        autoplay: hasAutoplay,
        muted: hasAutoplay ? true : false,
        controls: true,
        aspectRatio: isPortraitOnMobile()
      }"
      :videoSettings="{
        autoplay: hasAutoplay,
        muted: hasAutoplay ? true : false,
        controls: true,
        aspectRatio: isPortraitOnMobile()
      }"
      :youtubeSettings="{
        autoplay: hasAutoplay,
        muted: hasAutoplay ? true : false,
        controls: true,
        aspectRatio: isPortraitOnMobile()
      }"
      :vimeoSettings="{
        autoplay: hasAutoplay,
        muted: hasAutoplay ? true : false,
        controls: true,
        aspectRatio: isPortraitOnMobile()
      }"
      @ready="onPlayerReady"
      @pauseChanged="pauseChanged"
    />
  </ClientOnly>
</template>
